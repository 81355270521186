import React from 'react';
import "./Financial.css";
import StudentrefNavbar from "../../StudentrefNavbar/StudentrefNavbar";
import Footer from '../../Footer/Footer';
import { Link } from 'react-router-dom';
import img from "../../images/support group assets/Physical_1.png";
import img1 from "../../images/support group assets/Physical_2.png"

export default function Phyiscal() {
    return (
        <div>
            <StudentrefNavbar />
            <div className="support-group-main">
                <div className="support-group-sidebar">
                    <Link to="/supportgroups/spiritual" className="group-item "><div >Spiritual</div></Link>
                    <Link to="/supportgroups/social" className="group-item"><div >Social</div></Link>
                    <Link to="/supportgroups/career" className="group-item"><div >Career</div></Link>
                    <Link to="/supportgroups/creative" className="group-item"><div >Creative</div></Link>
                    <Link to="/supportgroups/emotional" className="group-item"><div >Emotional</div></Link>
                    <Link to="/supportgroups/environmental" className="group-item"><div >Environmental</div></Link>
                    <Link to="/supportgroups/intellectual" className="group-item"><div >Intellectual</div></Link>
                    <Link to="/supportgroups/financial" className="group-item"><div >Financial</div></Link>
                    <Link to="/supportgroups/physical" className="group-item group-active"><div >Physical</div></Link>
                </div>
                <div className="support-group-body">
                    <div className="group-body-head ">Physical</div>
                    <div className="group-content">
                        <div className="group-content-para grid-col-span-2">
                            Physical well-being encompasses various elements crucial for a healthy lifestyle, including maintaining a balanced diet,
                             regular exercise, adequate rest, and effective stress management. These components form the foundation of a comprehensive approach to enhancing your physical domain. Engaging in regular physical activity, such as aiming for 150 minutes of moderate aerobic activity per week along with muscle-strengthening exercises, promotes overall health. Pairing this with a balanced diet rich in fruits, vegetables, lean proteins, and healthy fats while limiting processed foods and sugar intake further supports your physical well-being. Additionally, prioritizing quality sleep, staying hydrated, and effectively managing stress through techniques like deep breathing and meditation contribute to maintaining optimal physical health.
                        </div>
                        <div className="group-content-para">
                            Incorporating these practices into your daily routine fosters a holistic approach to physical well-being. Establishing healthy habits like regular exercise, nutritious eating, and sufficient rest not only enhances your physical health but also promotes mental and emotional balance. Moreover, prioritizing stress management techniques and staying vigilant with regular health check-ups contribute to overall wellness. By embracing these strategies, you can cultivate a lifestyle that not only supports your physical health but also enhances your overall quality of life.
                        </div>
                        <div className='group-content-boxs'>
                            <div className='group-content-box'><img src={img} alt=""/></div>
                            <div className='group-content-box'><img src={img1} alt=""/></div>
                        </div>
                    </div>
                    <div className="group-body-head ">Community Platform</div>
                    <div className="community-platform">
                        <div className="community-comments">
                            <div className="community-comment-box">
                                <div className="comment-head">
                                    <div className="comment-name " >Jane</div>
                                    <div className="comment-time-head" >
                                        <div className="comment-time" >
                                            3 hours ago
                                        </div>
                                        <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                    </div>
                                </div>
                                <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morbi leo urna molestie at elementum eu. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non.</div>
                                <div className="comment-foot">
                                    <div>2 Comments</div>
                                    <div>Reply</div>
                                </div>
                            </div>
                            <div className="community-reply-box">
                                <div className="community-comment-box">
                                    <div className="comment-head">
                                        <div className="comment-name " >Jhon</div>
                                        <div className="comment-time-head" >
                                            <div className="comment-time" >
                                                1 hour ago
                                            </div>
                                            <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                        </div>
                                    </div>
                                    <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</div>
                                </div>
                                <div className="community-comment-box">
                                    <div className="comment-head">
                                        <div className="comment-name " >Sam</div>
                                        <div className="comment-time-head" >
                                            <div className="comment-time" >
                                                2 hour ago
                                            </div>
                                            <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                        </div>
                                    </div>
                                    <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</div>
                                </div>
                            </div>
                            <div className="community-comment-box">
                                <div className="comment-head">
                                    <div className="comment-name " >Ben</div>
                                    <div className="comment-time-head" >
                                        <div className="comment-time" >
                                            3 hours ago
                                        </div>
                                        <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                    </div>
                                </div>
                                <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morbi leo urna molestie at elementum eu. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non.</div>
                                <div className="comment-foot">
                                    <div>5 Comments</div>
                                    <div>Reply</div>
                                </div>
                            </div>

                        </div>
                        <div className="community-chat-input">
                            <div className="input-box">
                                <input type="text" placeholder="Start a post..."></input>
                                <button><i class='bx bx-up-arrow-alt'></i></button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <Footer/>
        </div >
    )
}