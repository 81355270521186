import React, { useState } from "react";
import img6 from "../images/homebg.jpg";
import "../Studentverify/Studentverify.css"
import img7 from "../images/..png"
import Navbar from "../Navbar/Navbar"
import Footer from "../Footer/Footer"
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {toast} from "react-toastify";

export default function Studentverify(){
    const[otp1,setOtp1]=useState('')
    const[otp2,setOtp2]=useState('')
    const[otp3,setOtp3]=useState('')
    const[otp4,setOtp4]=useState('')
    const[otp5,setOtp5]=useState('')
    const[otp6,setOtp6]=useState('')

    const navigate=useNavigate()
    const location=useLocation()
    const verifyEmail=async(e)=>{
        e.preventDefault()
        e.stopPropagation()
        try{

            const config = {
                headers: {
                    "Content-type": "application/json",
                },
            };
            
            const response=await axios.post("https://backend.thewellnessu.com/api/user/verify",{otp1,otp2,otp3,otp4,otp5,otp6,email:location.state},config)
            
            if (response.status===200){
                localStorage.setItem("wellnessuser", location.state)
                navigate("/success", {state: location.state})
                
            }else{
                
                navigate("/unsuccess")
            }
        }catch(error){
            console.log(error)
            toast.error("Something Wrong")
        }
    }
    return(
        <div>
            <div><Navbar/></div>
            <div className="student-verify">
                <img  className="verify-img" src={img6}/>
                <div className="logindiv">
                    <img src={img7}/>
                    <h4>Verify your Email to continue...</h4>
                    <div className="d-flex verify ">
                        <input type="text" maxlength="1" value={otp1} onChange={(e)=>setOtp1(e.target.value)}/>
                        <input type="text" maxlength="1" value={otp2} onChange={(e)=>setOtp2(e.target.value)}/>
                        <input type="text" maxlength="1" value={otp3} onChange={(e)=>setOtp3(e.target.value)}/>
                        <input type="text" maxlength="1" value={otp4} onChange={(e)=>setOtp4(e.target.value)}/>
                        <input type="text" maxlength="1" value={otp5} onChange={(e)=>setOtp5(e.target.value)}/>
                        <input type="text" maxlength="1" value={otp6} onChange={(e)=>setOtp6(e.target.value)}/>
                    </div>
                    <input type="submit" className="btn submit my-5" onClick={(e)=>verifyEmail(e)}/>
                </div>
            </div>
            <div className="verify-foot"><Footer/></div>
        </div>
    )
}