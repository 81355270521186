import React, { useState } from "react";
import "../Login/Login.css"
import Navbar from "../Navbar/Navbar";
import img6 from "../images/homebg.jpg";
import Footer from "../Footer/Footer";
import axios from "axios"
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

export default function Login() {
    const [userId, setuserId] = useState(null)
    const [password, setPassword] = useState(null)
    const [resourceCenter, setResourceCenter] = useState(null)
    const navigate = useNavigate()

    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const handleLogin = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                },
            };
            const response = await axios.post("https://backend.thewellnessu.com/api/crcUser/crclogin", { userId, password, resourceCenter }, config)
            navigate("/crcdashboard")
        } catch (error) {
            console.log(error, "Error occured")
            toast.error('Something went wrong!, please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }


    return (
        <div>
            <div><Navbar /></div>
            <div className="login">
                <img src={img6} />
                <div className="logindiv1 d-flex ">
                    <div className="m-4 d-flex flex-column">
                        <h4>Already affiliated with WellnessU?</h4>
                        <p className="text-start fw-medium">Login here</p>
                        <form >
                            <div className="d-flex flex-column"><label className="text-start my-2 fw-medium">User ID</label>
                                <input type="text" value={userId} onChange={(e) => setuserId(e.target.value)} />
                            </div>
                            <div className="d-flex flex-column">
                                <label className="text-start my-2 fw-medium">Select Resource Center</label>
                                <select value={resourceCenter} onChange={(e) => setResourceCenter(e.target.value)}>
                                    <option></option>
                                    <option></option>
                                </select>
                            </div>
                            <div className="d-flex flex-column">
                                <label className="text-start my-2 fw-medium">Password</label>
                                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                        </form>
                        <a className="text-start my-1">Forget Password/ID</a>
                        <Link to="/crcdashboard" className="btn my-2 login-button" onClick={(e) => handleLogin(e)}>Submit</Link>

                    </div>
                    <div className="dottedline"></div>
                    <div className="m-4 d-flex flex-column logindiv2">
                        <h4 className="text-start">Not affiliated yet?</h4>
                        <p className="text-start p1">Complete the form below, and we'll reach out to you shortly.</p>
                        <p className="text-start fw-medium">Why Join us?</p>
                        <ul>
                            <li className="text-start">We cut your functioning costs by 75%.</li>
                            <li className="text-start">Help us help your students</li>
                            <li className="text-start">Watch our demo to know more!</li>
                        </ul>
                        <Link to="/crcform"><button className="btn my-5 login-button">Access Form</button></Link>
                    </div>
                </div>
            </div>
            <div className="login-foot"><Footer/></div> 
            <ToastContainer />
        </div>
    )
}