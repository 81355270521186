import React, { useState } from "react";
import axios from "axios";
import Navbar from "../Navbar/Navbar";
import img6 from "../images/homebg.jpg"
import "./Forget.css"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

export default function ResetPassword() {
    const navigate = useNavigate()
    const location = useLocation()
    const email = location.email
    const [password, setPassword] = useState()
    const [repassword, setRepassword] = useState()
    const[otp,setOtp]=useState()
    const handlePassword=async(e)=>{
        e.preventDefault()
        e.stopPropagation()
        if (password === repassword) {
            try {
                const config = {
                    headers: {
                        "Content-type": "application/json",
                    },
                };
                const response = await axios.post("https://backend.thewellnessu.com/api/user/resetpassword", { email, password, otp }, config)
            
                if (response.status === 200) {
                    toast.success('Password Reset Successfully!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => { navigate("/Studentlogin") },5010)
                    
                }
                else {
                  
                    toast.error('Something went wrong!, please try again', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                 }
            
            } catch (error) {
                console.log(error, "Error occured"); 
                toast.error('Something went wrong!, please try again', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
        else { 
            console.log("passwords don't match")
            toast.error('Passwords don\'t match, please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    return(
        
        <div className="">
            <div><Navbar/></div>
            <div className="forget">
            <img src={img6} alt=""/>
            <div className="forget-div-1">
            <label>Enter a new password</label>
            <input type="password"   value={password} onChange={(e)=>setPassword(e.target.value)} />
            <label>Re-Enter password</label>
            <input type="password"  value={repassword} onChange={(e)=>setRepassword(e.target.value)} />
            <label>Enter a OTP</label>
            <input type="text"  value={otp} onChange={(e)=>setOtp(e.target.value)} />
            <button onClick={handlePassword}>submit</button>
            </div>
            
            </div>
            <ToastContainer />
        </div>
    )
}