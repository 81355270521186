import React from "react";
import "../About/About.css"
import Team from "../Team/Team";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";


export default function About() {
    return (
        <div id="about">
            <Navbar />
            <div className="about-cont">
                <p>Our Journey </p>
                <div className="about-texxt">
                    <div className="yellow1 rect"><div className="action-content"><span><b>October 2023: </b> Idea Inception - Our Founder begins ideating solutions for student wellness issues after discussions with university resource centers. Identified challenges include understaffing and the lengthy process (3-5 sessions) to understand student issues.</span></div></div>
                    <div className="line1"></div>
                    <div className="d-blue1 rect"><div className="action-content"><span><b>November 2023: </b>Conceptualization - WellnessU is conceptualized to address the identified challenges. The platform aims to quantify wellness using survey data mapped to scoring and presented on comprehensive dashboards.</span></div></div>
                    <div className="line2"></div>
                    <div className="blue1 rect"><div className="action-content"><span><b>December 2023: </b>Initial Development - Using MS Excel, the team begins developing prototypes of the wellness dimensions and dashboards. Collaborates with psychology experts at UCI to refine the questionnaire.</span></div></div>
                    <div className="line3"></div>
                    <div className="yellow2 rect"><div className="action-content"><span><b>January 2024: </b>Participation in UCI AI Innovation Challenge - WellnessU secures 3rd position (USD 2000), boosting confidence and validating the concept.
                        February 2024: Platform Development begins - Based on positive feedback and verbal contracts from resource centers, full-scale development of the WellnessU platform commences</span></div></div>
                    <div className="line4"></div>
                    <div className="d-blue2 rect"><div className="action-content"><span><b>March 2024: </b>Mentorship and Competitions - Engages with mentors/advisors and engages in the first round of various competitions including UCI Beall Butterworth Competition, Stella Zhang New Venture Competition and TiEU Global Collegiate Startup Competition. </span></div></div>
                    <div className="line5"></div>
                    <div className="blue2 rect"><div className="action-content"><span><b>April 2024: </b>Progress and Recognition - Selected as semi-finalists in UCI Stella Zhang New Venture Competition and Beall Butterworth Competitions. Represented TiEU SoCal chapter at Global TiEU  Collegiate Competition in Silicon Valley on April 30th.</span></div></div>
                </div>

            </div>
            <Team />
            <div className="about-footer">
                <Footer />
            </div>
        </div>

    )
}