import React from "react"
import Navbar from "../Navbar/Navbar"
import img6 from "../images/homebg.jpg";
import "./Onboarding.css";
import axios from "axios"
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";

export default function Onboarding() {
    const email = localStorage.getItem("wellnessuser");
    const [onboardingData, setOnboardingData] = React.useState({
        name:'',
        email: email,
        refered:false,
        university:"Select a university",
        degree:'Select a degree',
        category:'Select a category',
        gender:'Select your gender',
        ethnicity:'Select your ethnicity',
        parentDeg:false
    })
    
    const checkAllFields = () => { 
        if (onboardingData["name"] === '' ||
            onboardingData["university"] === "Select a university"
            || onboardingData["degree"] === "Select a degree"
            || onboardingData["category"] === "Select a category"
            || onboardingData["gender"] === "Select your gender"
            || onboardingData["ethnicity"] === "Select your ethnicity")
            return false;
        return true;
    }
   
    const navigate=useNavigate()
    function handleChange(event){
        const {name,value} = event.target;
        setOnboardingData(prevState =>{
            return {
                ...prevState,
                [name]:value
            }
        })
      
    }

    const submitOnboard = async(e)=>{
        e.preventDefault()
        e.stopPropagation()
        try{
            const config = {
                headers:{
                    "Content-type":"application/json",
                },
            };
            const response = await axios.post("https://backend.thewellnessu.com/api/user/onboard",{onboardingData},config);
            if (onboardingData.refered === true) {
                localStorage.setItem("is_refered", "true")
                navigate("/studentref",{state:onboardingData.email});
                
            }
            else {
                localStorage.setItem("is_refered", "false")
                navigate("/independent",{state:onboardingData.email}); 
            }
        }
        catch(error){
            console.log(error)
        }

    }
    return(
        <div>
            <div><Navbar/></div>
            <div>
            <img className="onboarding-img" src={img6}/>
            <div className=" onboarding">
                <h4 className=""> Student Onboarding Questionnaire</h4>
                <div className="text-start">
                    <div className="d-flex flex-row justify-content-between my-3 board">
                        <div><label>Name</label></div>
                            <div><input name = 'name' value={onboardingData.name} onChange={handleChange}></input></div>
                        
                    </div>
                    
                    <div className="d-flex flex-row justify-content-between my-3 board">
                        <div><label className="text-start">Were you referred by your Campus Resource Center?</label></div>
                        <div className="d-flex">
                        <div onClick ={()=> setOnboardingData(prevState => ({...prevState,refered:true}))} className={`xx mx-4 ${onboardingData.refered ? 'bg-yellow':''}`}>Yes</div>
                                <div onClick={() => setOnboardingData(prevState => ({ ...prevState, refered: false }))} className={`xx ${!onboardingData.refered ? 'bg-yellow' : ''}`}>No</div>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3 board">
                        <div><label>University</label></div>
                        <div>
                                <select name='university' value={onboardingData.university} onChange={handleChange}>
                                    <option disabled selected>Select a university</option>
                                    <option>UC Berkeley</option>
                                    <option>UC Davis</option>
                                    <option>UC Irvine</option>
                                    <option>UCLA</option>
                                    <option>UC Merced</option>
                                    <option>UC Riverside</option>
                                    <option>UC San Diego</option>
                                    <option>UC San Francisco</option>
                                    <option>UC Santa Barbara</option>
                                    <option>UC Santa Cruz</option>
                        </select>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3 board">
                        <div><label>Which degree are you currently pursuing?</label></div>
                        <div>
                                <select name='degree' value={onboardingData.degree} onChange={handleChange}>
                                    <option disabled selected>Select a degree</option>
                                    <option>Undergraduate</option>
                                    <option>Graduate</option>
                                    <option>PHD</option>
                                    
                        </select>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3 board">
                        <div><label>Student Category</label></div>
                        <div>
                                <select name='category' value={onboardingData.category} onChange={handleChange}>
                                    <option disabled selected>Select a category</option>
                            <option>International Student</option>
                                    <option>National Student</option>
                        </select>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row  justify-content-between my-3 board">
                        <div><label>Gender</label></div>
                        <div>
                                <select name='gender' value={onboardingData.gender} onChange={handleChange}>
                                    <option disabled selected>Select your gender</option>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Other</option>
                        </select>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3 board">
                        <div><label>Ethnicity</label></div>
                        <div>
                                <select name='ethnicity' value={onboardingData.ethnicity} onChange={handleChange}>
                            <option disabled selected>Select your ethnicity</option>
                                    <option>White</option>
                                    <option>African American</option>
                                    <option>Asian</option>
                                    <option>Native Hawaiian or Other Pacific Islander</option>
                                    <option>Hispanic or latino</option>
                                    <option>Arab</option>
                        </select>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3 board">
                        <div><label className="text-start">Do either of your parents hold a 4-year degree?</label></div>
                        
                        <div className="d-flex">
                                <div onClick={() => setOnboardingData(prevState => ({ ...prevState, parentDeg: true }))} className={`xx mx-4 ${onboardingData.parentDeg ? 'bg-yellow' : ''}`}>Yes</div>
                                <div onClick={() => setOnboardingData(prevState => ({ ...prevState, parentDeg: false }))} className={`xx  ${!onboardingData.parentDeg ? 'bg-yellow' : ''}`}>No</div>
                        </div>
                        
                    </div>
                    </div>
                    {checkAllFields() ?
                        (<button onClick={(e) => submitOnboard(e)} className="btn">Submit</button>)
                        :
                        (<button className="btn btn-disabled">Submit</button>)}
               

            </div>
            </div>
            <div className="onboarding-foot"> <Footer/></div>
          
        </div>
    )
}