import React from "react";
import img3 from "../images/logo.png"
import { Link,useNavigate } from "react-router-dom"; 

import "./CRCnavbar.css"

export default function CRCnavbar(){
    const[toggledrop,setToggleDrop] = React.useState(false);
    const navigate=useNavigate();
    const handleLogout=()=>{
        navigate("/CRClogin")
    }
    return(
        <div className="crc-nav-bar">
            <nav className="row">
                <div className="col-md-6 text-start"><img src={img3}/></div>
                <div className="col-md-6 ">
                    <ul className="row fs-5 d-flex">
                        <a className=" col anchor dropdown-navbar" >
                        <span onClick = {()=> setToggleDrop(prevState => !prevState)}>Descriptive Analytics</span>
                           { toggledrop &&  <div className="dropdown-content-navbar ">
                                <Link to="/q1"><a href="">Q1</a> </Link>
                                <Link to="/q1"><a href="">Q2</a> </Link>
                                <Link to="/q1"><a href="">Q3</a> </Link>
                                <Link to="/q1"><a href="">Q4</a> </Link>
                            </div>}
                        </a>
                        <Link to="/crcpredictive" className="col"><a className=" anchor" href="">Predictive analytics</a></Link>
                        <Link to="/reachout" className="col"><a className=" anchor" >Contact Us</a></Link>
                        <div onClick={handleLogout} className="col" ><a className=" anchor" >Logout</a></div>
                        
                        
                    </ul>
                </div>
            </nav>
        </div>
    )
}