import React, { useState,useEffect } from "react";
import StudentrefNavbar from "../StudentrefNavbar/StudentrefNavbar";
import "./StudentProfile.css"
import img8 from "../images/sos.png"
import Footer from "../Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

export default function StudentProfile(){
    const navigate=useNavigate()
    const[data,setData]=useState({
        name:"",
        email:"",
        university:"",
        category:"",
        degree:"",
        gender:"",
        ethnicity:""
    })
    const location=useLocation()
    const data1 = location.state;
    
    const handleUpdate=()=>{
        navigate("/profile/edit")
    }
    useEffect(() => {
        handleProfile();
    }, []);
    const handleProfile=async()=>{
        try{
            const config = {
                headers:{
                    "Content-type":"application/json",
                },
            };
            const response = await axios.post("https://backend.thewellnessu.com/api/user/profile",{email:data1.data},config);
            console.log(response.data)
            setData(response.data)
        }
        catch(error){
            console.log("Profile fetching Error")
            console.log(error)
        }

    }
    return(
        <div>
            <div><StudentrefNavbar/></div>
            <div className="student-profile">
                <div className="student-box d-flex container">
                    <div className="profilediv ">
                        <div><img className="profile-img " src={img8}/></div>
                        <div><button className="btn edit-btn" onClick={handleUpdate}>Edit</button></div>
                        
                    </div>
                    <div className=" vl"></div>
                    <div className=" fs-6 submain fw-bolder">
                    <div className="d-flex flex-row   main">
                        <div><label>Name</label></div>
                        <div className="">{data.name}</div>
                        
                    </div>
                    <div className="d-flex flex-row   main">
                        <div><label>Email</label></div>
                        <di className="">{data.email}</di>
                        
                    </div>
                    <div className="d-flex flex-row   main">
                        <div><label>University</label></div>
                        <div className="">{data.university}</div>
                        
                    </div>
                    <div className="d-flex flex-row   main">
                        <div><label>Degree</label></div>
                        <div>{data.degree}</div>
                        
                    </div>
                    <div className="d-flex flex-row   main">
                        <div><label>Student Category</label></div>
                        <div>{data.category}</div>
                        
                    </div>
                    <div className="d-flex flex-row    main">
                        <div><label>Gender</label></div>
                        <div>{data.gender}</div>
                        
                    </div>
                    <div className="d-flex flex-row   main">
                        <div><label>Ethnicity</label></div>
                        <div>
                       {data.ethnicity}
                        </div>
                        
                    </div>
                    
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}