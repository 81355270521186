import React ,{useEffect,useState}from 'react';
import StudentrefNavbar from "../../StudentrefNavbar/StudentrefNavbar";
import "./Wellnessexpert.css"
import group40 from "../../images/Group 40.png";
import group41 from "../../images/Group 41.png";
import group47 from "../../images/Group 47.png";
import group45 from "../../images/Group 45.png";
import group42 from "../../images/Group 42.png";
import group39 from "../../images/Group 39.png";
import group43 from "../../images/Group 43.png";
import group44 from "../../images/Group 44.png";
import group46 from "../../images/Group 46.png";
import Footer from "../../Footer/Footer";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import axios from "axios";

export default function Wellnessexpert() {
    const location = useLocation();
    const data1 = localStorage.getItem("wellnessuser");
    // console.log()
    // localStorage.setItem('email', data1);
    // console.log("data1 is " + data1);
    let [wellnessPercentage,setWellnessPercentage] = useState(0);
    const [dimension, setDimension] = useState(["Career","Creative","Emotional","Environmental","Intellectual","Financial","Physical","Spiritual","Social"]);
    const[dimensionName,setDimensionName]=useState({
       Career:false,
       Creative:false,
       Emotional:false,
       Environmental:false,
       Intellectual:false,
       Financial:false,
       Physical:false,
       Spritual:false,
       Social:false

    })
    useEffect(() => {
        handleData();
    }, [dimension]);
    useEffect(() => {
        handlePercentage();
    }, [dimensionName]);
    const handleData=async()=>{
        

        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                },
            };
            
            for(let i =0;i<9;i++){
                
                const response = await axios.get(`https://chat.thewellnessu.com/check-completion?username=${data1}&dimension=${dimension[i]}`, config)
                if (response.data.status == "pending") {
                    setDimensionName(prevStatus => ({
                        ...prevStatus,
                        [dimension[i]]: false,
                    }));
                } else {
                    setDimensionName(prevStatus => ({
                        ...prevStatus,
                        [dimension[i]]: true,
                    }));
                }
            }
            
            
            
        } catch (error) {
            console.log(error, "Error occured")
        }
        
    }
    function handlePercentage(){
        setWellnessPercentage(0)
        for (let i = 0; i < 9; i++) {
            if(dimensionName[dimension[i]]==true){
                setWellnessPercentage(prevState => prevState + 1)
            }
        }
    }
    return (
        <div className="studentref">
            <StudentrefNavbar email ={data1}/>
            <div className="wellness-main">
                <div className="wellness-head">9 Wellness Experts</div>
                <div className='wellness-progress d-flex align-items-center gap-4 ms-4 me-4'>
                    <div>Your Progress</div>
                    <div class="progress" style={{ "width": "60rem","height":"1.5rem" }}role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                        <div class="progress-bar" style={{ "width": `${wellnessPercentage / 9 * 100}%`, "background-color": " #FFD800", "color": "black" }}>{(wellnessPercentage / 9 * 100).toFixed(2)}%</div>
                    </div>
                </div>
                <div className="wellness-items-1">
                    <Link to="/studentref/career" state={{ data:"Career", email: data1 }} onClick={() => setDimension("Career")}>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group40} />
                            <div className="item-progress">{dimensionName.Career?"100%":"0%"}</div>
                        </div>
                            <div className='sub-name'>Career</div>
                    </div></Link>
                    <Link to="/studentref/creative" state={{ data: "Creative", email: data1 }} onClick={() => setDimension("Creative")}>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group41} />
                            <div className="item-progress">{dimensionName.Creative?"100%":"0%"}</div>
                            </div>
                            <div className='sub-name'>Creative</div>
                    </div></Link>
                    <Link to="/studentref/emotional" state={{ data: "Emotional", email: data1 }} onClick={() => setDimension("Emotional")}>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group47} />
                            <div className="item-progress">{dimensionName.Emotional?"100%":"0%"}</div>
                            </div>
                            <div className='sub-name'>Emotional</div>
                    </div></Link>
                    <Link to="/studentref/environmental" state={{ data: "Environmental", email: data1 }} onClick={() => setDimension("Environmental")}>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group45} />
                            <div className="item-progress">{dimensionName.Environmental?"100%":"0%"}</div>
                            </div>
                            <div className='sub-name'>Environmental</div>
                    </div></Link>
                    <Link to="/studentref/intellectual" state={{ data: "Intellectual", email: data1 }} onClick={() => setDimension("Intellectual")}>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group42} />
                            <div className="item-progress">{dimensionName.Intellectual?"100%":"0%"}</div>
                            </div>
                            <div className='sub-name'>Intellectual</div>
                    </div></Link>
                    
                    
                </div>
                <div className="wellness-items-2">
                    <Link to="/studentref/financial" state={{ data: "Financial", email: data1 }} onClick={() => setDimension("Financial")}>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group39} />
                            <div className="item-progress">{dimensionName.Financial?"100%":"0%"}</div>
                        </div>
                            <div className='sub-name'>Financial</div>
                    </div></Link>
                    <Link to="/studentref/physical" state={{ data: "Physical", email: data1 }} onClick={() => setDimension("Physical")}>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group43} />
                            <div className="item-progress">{dimensionName.Physical?"100%":"0%"}</div>
                            </div>
                            <div className='sub-name'>Physical</div>
                    </div></Link>
                    <Link to="/studentref/spiritual" state={{ data: "Spiritual", email: data1 }} onClick={() => setDimension("Spiritual")}>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group44} />
                            <div className="item-progress">{dimensionName.Spiritual?"100%":"0%"}</div>
                            </div>
                            <div className='sub-name'>Spritual</div>
                    </div></Link>
                    <Link to="/studentref/social" state={{ data: "Social", email: data1  }} onClick={() => setDimension("Social")}>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group46} />
                            <div className="item-progress">{dimensionName.Social?"100%":"0%"}</div>
                            </div>
                            <div className='sub-name'>Social</div>
                    </div></Link>
                    
                    
                </div>
            </div>

            <Footer/> 
        </div>
    )
}


