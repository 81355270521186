import React from "react"
import Navbar from "../Navbar/Navbar"
import img6 from "../images/homebg.jpg";
import img7 from "../images/success.png"
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
export default function Verifysuccessful() {
    const location = useLocation();
    const email = location.state;
    console.log("login email ", email);
    localStorage.setItem('email', email);
    return(
        <div>
            <div><Navbar/></div>
            <div>
            <img className="sumit-img" src={img6}/>
            <div className="logindiv">
               <img src={img7} className="my-2 illustrator"/>
                <p className="crc-p m-2">Congratulations! Your Email has been verified.</p>
                    <Link to="/onboarding" className="btn login-button">Get Started</Link>
            </div>
            </div>
            <div className="crc-sumit"><Footer/></div>
        </div>
    )
}