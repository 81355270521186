import React from "react"
import Navbar from "../Navbar/Navbar"
import img6 from "../images/homebg.jpg";
import "../Crc/crcsumit.css"
import Footer from "../Footer/Footer";
import img7 from "../images/svgviewer-png.png"


export default function Crcsumit(){
    return(
        <div>
            <div><Navbar/></div>
            <div className="sumit">
            <img className="sumit-img" src={img6}/>
            <div className="logindiv crcsumit">
               <img src={img7} className="my-2 illustrator"/>
                <p className="crc-p m-2">Thank you for submitting the form! We're thrilled to have you join us. Our team will be in touch with you soon</p>
            </div>
            </div>
            <div className="crc-sumit"><Footer/></div>
        </div>
    )
}