import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Forget.css"
import Navbar from "../Navbar/Navbar";
import img6 from "../images/homebg.jpg"

export default function ForgetPassword(){
    const[email,setEmail]=useState()
    const navigate=useNavigate()
    const handlePassword=async(e)=>{
        e.preventDefault()
        e.stopPropagation()
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                },
            };
            const response = await axios.post("https://backend.thewellnessu.com/api/user/emailsend", { email }, config)
            navigate("/resetpassword", {email: email})
            console.log(response)
            
        } catch (error) {
            console.log(error, "Error occured")
        }
    }

    return(
        
        <div className="">
            <div><Navbar/></div>
            <div className="forget">
                <img src={img6} alt=""/>
                <div className="forget-div-1"><label>Enter a Email</label><input type="text"  value={email} onChange={(e)=>setEmail(e.target.value)} />
                 <button onClick={handlePassword}>submit</button>
                </div>
            </div>
            
        </div>
    )
}