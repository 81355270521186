import React from "react";
import "../Home/Home.css"
import img1 from "../images/homebg.jpg";
import img2 from "../images/vector.png"
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import img3 from "../images/campus-student-image.png"

export default function Home() {
    return (
        <div >
            <Navbar />
            <div className="row home-intro">
                <div className="col-8 home-div1"><img src={img1} /></div>
                <div className="home-div2 col-4 ">
                    <img src={img2} />
                    <p className="text-end">Meet our <span className="fw-bolder">Generative AI</span> wellness companion, enhancing student experience with <span className="fw-bolder">Predictive Analytics</span> across multiple wellness dimensions. Because in today's world, student well-being is as important as their grades!
                    </p>
                    <button className="btn btn-light b-1">I'm a Student</button>
                    <button className="btn btn-light b-2">I'm a Resource center</button>
                </div>
            </div>

            <div className="wellness-2">
                <div className='wellness-2-img'>
                    <div><img src={img3} /></div>
                    <div><h4>9 Wellness Dimensions</h4></div>
                </div>
                <div className="all-wellness">
                    <div className=" wellness-main-box grid-2-center">
                        <div className='wellness-box'>
                            <div className='wellness-head'>Career</div>
                            <div className='wellness-content'>A professionally sound (or career well) person engages in work to gain personal satisfaction and enrichment, consistent with values, goals and lifestyle.</div>
                        </div>
                    </div>

                    <div className="wellness-main-box grid-mid" ><div className='wellness-box'>
                        <div className='wellness-head'>Emotional</div>
                        <div className='wellness-content'>An emotionally well person can identify, express and manage the entire range of feelings and would consider seeking assistance to address areas of concern.</div>
                    </div></div>
                    <div className="wellness-main-box grid-mid-rev" ><div className='wellness-box'>
                        <div className='wellness-head'>Environmental</div>
                        <div className='wellness-content'>An environmentally well person recognizes the responsibility to preserve, protect, and improve the environment and appreciates the interconnectedness of nature and the individual.</div>
                    </div></div>
                    <div className="wellness-main-box grid-mid-2" ><div className='wellness-box'>
                        <div className='wellness-head'>Financial</div>
                        <div className='wellness-content'>A financially well person is fully aware of financial state and budgets, saves and manages finances in order to achieve realistic goals.</div>
                    </div></div>
                    <div className="wellness-main-box grid-mid-2-rev" > <div className='wellness-box'>
                        <div className='wellness-head'>Spritual</div>
                        <div className='wellness-content'>A spiritually well person seeks harmony and balance by openly exploring the depth of human purpose, meaning, and connection through dialogue and self-reflection.</div>
                    </div></div>
                    <div className="wellness-main-box grid-mid" ><div className='wellness-box'>
                        <div className='wellness-head'>Social</div>
                        <div className='wellness-content'>A socially well person has a network of support based on interdependence, mutual trust, respect and has developed a sensitivity and awareness towards the feelings of others.</div>
                    </div></div>
                    <div className="wellness-main-box grid-mid-rev" ><div className='wellness-box'>
                        <div className='wellness-head'>Creative</div>
                        <div className='wellness-content'>A creatively well person values and actively participates in a diverse range of arts and cultural experiences as a means to understand and appreciate the surrounding world.</div>
                    </div></div>
                    <div className="wellness-main-box grid-end-1" ><div className='wellness-box'>
                        <div className='wellness-head'>Intellectual</div>
                        <div className='wellness-content'>An intellectually well person values lifelong learning and seeks to foster critical thinking, develop moral reasoning, expand worldviews, and engage in education for the pursuit of knowledge.</div>
                    </div></div>
                    <div className="wellness-main-box" ><div className='wellness-box'>
                        <div className='wellness-head'>Physical</div>
                        <div className='wellness-content'>A physically well person gets an adequate amount of sleep, eats a balanced and nutritious diet, engages in exercise for 150 minutes per week, attends regular medical check-ups, and practices safe and healthy sexual relations.</div>
                    </div></div>

                </div>
            </div>



            <div className="home-testimonials">
                <h4 className="my-4 fw-bold">Testimonials</h4>
                <div className="d-flex my-5 container">
                    <div className="testimonials-box">

                        <img src={require("../images/testimonials/dr-david-spight.jpeg")} alt="Dr David Spight" />
                        <h5>Dr David Spight</h5>
                        <h6><i>Director Undergraduate</i></h6>
                        <h6><i>Undeclared Advising Office, UCI</i></h6>
                        <p className="my-3">"The insights that WellnessU's Dashboard can provide wouldvsignificantly alleviate our resource cconstraints and improve outcomes for students"
                        </p>
                    </div>
                    <div className="testimonials-box">
                        <img src={require("../images/testimonials/Tom-Andriola.jpeg")} alt="Tom Andriola" />
                        <h5>Tom Andriola</h5>
                        <h6><i>Vice Chancellor Information,</i></h6>
                        <h6><i>Technology and Data, UCI</i></h6>
                        <p className="my-3">"WellnessU can have a huge impact in addressing the overall well being of student on campus by their innovative use of Data Analytics and Al"
                        </p>
                    </div>
                    <div className="testimonials-box">
                        <img src={require("../images/testimonials/Suzanne-Helbig.jpeg")} alt="Suzanne Helbig" />
                        <h5>Suzanne Helbig</h5>
                        <h6><i>Associate Vice Provost</i></h6>
                        <h6><i>Division of Career Pathways, UCI</i></h6>
                        <p className="my-3">"I am thrilled by WellnessU promise to transform how we approach wellness and career guidance at UCI. "
                        </p>
                    </div>
                </div>
                <div>
                    <h4>Share your experience with us and help others discover WellnessU!</h4>
                    <form className="d-flex flex-column testimonials-content container ">
                        <div className="sub-content">
                            <label>Name</label>
                            <input className="name-input" />
                        </div>
                        <div className="sub-content">
                            <label>Email</label>
                            <input className="name-input" />
                        </div>
                        <div className="sub-content">
                            <label>Review</label>
                            <input className="review-input" />
                        </div>
                        
                    </form>
                    <button type="submit" className="testimonials-button">Submit</button>
                </div>
            </div>

        <Footer/>



        </div>
    )
}