import React, { useEffect, useState } from 'react';
import axios from "axios";
import "../Sidepanel/sidepanel.css";

export default function Sidepanel() {
    const email = localStorage.getItem("wellnessuser");
    const [dimension, setDimension] = useState(["Career", "Creative", "Emotional", "Environmental", "Intellectual", "Financial", "Physical", "Spiritual", "Social"]);
    const [dimensionName, setDimensionName] = useState({
        Career: false,
        Creative: false,
        Emotional: false,
        Environmental: false,
        Intellectual: false,
        Financial: false,
        Physical: false,
        Spiritual: false,
        Social: false

    })
    useEffect(() => {
        handleData();
    }, [dimension]);
    const handleData = async () => {


        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                },
            };

            for (let i = 0; i < 9; i++) {

                const response = await axios.get(`https://chat.thewellnessu.com/check-completion?username=${email}&dimension=${dimension[i]}`, config)
                if (response.data.status == "pending") {
                    setDimensionName(prevStatus => ({
                        ...prevStatus,
                        [dimension[i]]: false,
                    }));
                } else {
                    setDimensionName(prevStatus => ({
                        ...prevStatus,
                        [dimension[i]]: true,
                    }));
                }
            }



        } catch (error) {
            console.log(error, "Error occured")
        }

    }
    
    return(
        <div >
            <div className="sidepanel ">
                <div className="sidepanel-menu"><i class='bx bx-menu'></i></div>
                <div className="sidepanel-box ">
                    <div>{dimensionName.Spiritual ? "100%" : "0%"}</div>
                    <p>Spiritual</p>
                </div>
                <div className="sidepanel-box ">
                    <div>{dimensionName.Social ? "100%" : "0%"}</div>
                    <p>Social</p>
                </div>
                <div className="sidepanel-box"> 
                    <div>{dimensionName.Career ? "100%" : "0%"}</div>
                    <p>Career</p>
                </div>
                <div className="sidepanel-box"><div>{dimensionName.Creative ? "100%" : "0%"}</div>
                    <p>Creative</p></div>
                <div className="sidepanel-box"><div>{dimensionName.Emotional ? "100%" : "0%"}</div>
                    <p>Emotional</p></div>
                <div className="sidepanel-box"><div>{dimensionName.Environmental ? "100%" : "0%"}</div>
                    <p>Environmental</p></div>
                <div className="sidepanel-box"><div>{dimensionName.Intellectual ? "100%" : "0%"}</div>
                    <p>Intellectual</p></div>
                <div className="sidepanel-box"><div>{dimensionName.Financial ? "100%" : "0%"}</div>
                    <p>Financial</p></div>
                <div className="sidepanel-box"><div>{dimensionName.Physical ? "100%" : "0%"}</div>
                    <p>Physical</p></div>
            </div>

        </div>
    )
}