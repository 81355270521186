import React from "react"
import Navbar from "../Navbar/Navbar"
import img7 from "../images/homebg.jpg";
import "../Crc/crcform.css"
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Crcform(){
    const [crcFormData, setCrcFormData] = React.useState({
        name:'',
        resourceCenter:"",
        password:"",
        email:'',
        university:"Select a university",
        wellnessAddress:"Select",
        studentOutreach:"Select",
        userId:'',
        previousWellness:false,
        wellnessGoal:"",
        additionalInformation:"",
        communication:'Select mode of communication',
        participation:false,
        knowAbout:"Select",
    })

    const navigate=useNavigate()

    function handleChanges(event){
        const {name,value} = event.target;
        setCrcFormData(prevState =>{
            return {
                ...prevState,
                [name]:value
            }
        })

        console.log(crcFormData);
    }
    const handleForm=async(e)=>{
        e.preventDefault()
        e.stopPropagation()

        try{
            const config = {
                headers:{
                    "Content-type":"application/json",
                },
            };
            const response = await axios.post("https://backend.thewellnessu.com/api/crcUser/crcregister",{crcFormData},config);
            navigate("/crcsumit")
        }
        catch(error){
            console.log(error)
        }
    }
    return(
        <div>
            <div><Navbar/></div>
            <div className="">
                <img className="crcform-img" src={img7}/>
                <div className="logindiv3 ">
                    <h4>CRC Affiliation Form</h4>
                    <div className="d-flex">
                        <div className="crcdiv">
                            <div className="d-flex column justify-content-lg-between my-4 crc-content"><p className="text-start fw-medium   ">Name</p>
                            <input className="form-input " name ="name" value ={crcFormData.name} onChange={(e) => handleChanges(e)} />
                            </div>
                            <div className="d-flex column justify-content-lg-between my-4  crc-content"><p className="text-start fw-medium   ">Resource Center Name</p>
                                <input className="form-input " name="resourceCenter" value ={crcFormData.resourceCenter} onChange={(e) => handleChanges(e)}/>
                            </div>
                            <div className="d-flex column justify-content-lg-between my-4  crc-content"><p className="text-start fw-medium   ">Email</p>
                            <input  className="form-input " name ="email" value ={crcFormData.email} onChange={(e) => handleChanges(e)}/>
                            </div>
                            
                            <div className="d-flex column justify-content-lg-between my-4  crc-content"><p className="text-start fw-medium   ">University</p>
                            <select className="form-input " name='university' value ={crcFormData.university} onChange={(e) => handleChanges(e)}>
                                    <option disabled selected>Select a university</option>
                                    <option>UC Berkeley</option>
                                    <option>UC Davis</option>
                                    <option>UC Irvine</option>
                                    <option>UCLA</option>
                                    <option>UC Merced</option>
                                    <option>UC Riverside</option>
                                    <option>UC San Diego</option>
                                    <option>UC San Francisco</option>
                                    <option>UC Santa Barbara</option>
                                    <option>UC Santa Cruz</option>
                        </select></div>
                            <div className="d-flex column justify-content-lg-between my-4  crc-content"><p className="text-start fw-medium   ">Top one wellness dimensions you address</p>
                            <select className="form-input " name='wellnessAddress' value ={crcFormData.wellnessAddress} onChange={(e) => handleChanges(e)} >
                                    <option disabled selected>Select </option>
                                    <option>Emotional</option>
                                    <option>Spritual</option>
                                    <option>Environmental</option>
                                    <option>Career</option>
                                    <option>Creative</option>
                                    <option>Financial</option>
                                    <option>Physical</option>
                                    <option>Social</option>
                                    <option>Environment</option>
                        </select></div>
                            <div className="d-flex column justify-content-lg-between my-4  crc-content"><p className="text-start fw-medium   ">Extent of students outreach</p>
                            <select className="form-input " name='studentOutreach'  value ={crcFormData.studentOutreach} onChange={(e) => handleChanges(e)}>
                                    <option disabled selected>Select </option>
                                    <option>500</option>
                                    <option>1000</option>
                                    <option>2000</option>
                                    <option>5000 +</option>
                        </select></div>
                            <div className="d-flex column justify-content-lg-between my-4  crc-content"><p className="text-start fw-medium   ">User ID</p>
                            <input  className="form-input " name='userId'  value ={crcFormData.userId} onChange={(e) => handleChanges(e)} /></div>
                            <div className="d-flex column justify-content-lg-between my-4"><p className="text-start fw-medium   ">Previous Experience with wellness Support Services</p>
                            <div className="d-flex form-div  ">
                                <div style ={{"cursor":"pointer"}}  onClick ={()=> setCrcFormData(prevState => ({...prevState,previousWellness:true}))} className={`${crcFormData.previousWellness ? 'bg-yellow':''}`}>Yes</div>
                                <div  style ={{"cursor":"pointer"}} onClick ={()=> setCrcFormData(prevState => ({...prevState,previousWellness:false}))} className={`mx-2 ${!crcFormData.previousWellness ? 'bg-yellow':''}`}>No</div>
                            </div></div>
                            <div className="d-flex column justify-content-lg-between my-4  crc-content"><p className="text-start fw-medium   ">Wellness Goals or Expectations</p>
                            <input  className="form-input " name ="wellnessGoal" value ={crcFormData.wellnessGoal} onChange={(e) => handleChanges(e)}/></div>
                            <div className="d-flex column justify-content-lg-between my-4  crc-content"><p className="text-start fw-medium   ">Additional information/ questions </p>
                            <input  className="form-input " name ="additionalInformation" value ={crcFormData.additionalInformation} onChange={(e) => handleChanges(e)}/></div>
                            <div className="d-flex column justify-content-lg-between my-4  crc-content"><p className="text-start fw-medium   ">Preferred mode of communication</p>
                            <select className="form-input " name='communication' value ={crcFormData.communication} onChange={(e) => handleChanges(e)}>
                                    <option disabled selected>Select mode of communication </option>
                                    <option>Online</option>
                                    <option>Offline</option>
                        </select></div>
                            <div className="d-flex column justify-content-lg-between my-4  crc-content"><p className="text-start fw-medium   ">Would you like to participate in Support Groups or Workshops</p>
                            <div className="d-flex form-div  ">
                            <div style ={{"cursor":"pointer"}} onClick ={()=> setCrcFormData(prevState => ({...prevState,participation:true}))} className={`${crcFormData.participation ? 'bg-yellow':''}`}>Yes</div>
                                <div style ={{"cursor":"pointer"}} onClick ={()=> setCrcFormData(prevState => ({...prevState,participation:false}))} className={`mx-2 ${!crcFormData.participation ? 'bg-yellow':''}`}>No</div>
                            </div></div>
                            
                            <div className="d-flex column justify-content-lg-between my-4  crc-content"><p className="text-start fw-medium   ">How did the you hear about WellnessU?</p><select className="form-input " name='knowAbout'  value ={crcFormData.knowAbout} onChange={(e) => handleChanges(e)}>
                                    <option disabled selected>Select </option>
                                    <option>Instagram</option>
                                    <option>Facebook</option>
                                    <option>Twitter</option>
                        </select></div>
                            
                            <div className="d-flex gap-2"><input type="checkbox" required/><span className="fw-medium">Opt-in for Newsletter or Updates</span></div>
                            <div className="d-flex gap-2"><input type="checkbox" required/><span className="fw-medium">Privacy Policy Agreement</span></div>
                            <div className="d-flex gap-2"><input type="checkbox" required/><span className="fw-medium">Consent for Data Collection and Usage</span></div>
                        </div>
                    </div>
                    <Link to="/crcsumit"><input type="submit" className="btn submit-btn" onClick={(e)=>handleForm(e)}/></Link>
                </div>
            </div>
            <div className="crc-form"><Footer/></div>
        </div>
    )
}