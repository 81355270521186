import React from "react";
import "../Footer/Footer.css";
import img5 from "../images/Vectorcopy.png"

export default function Footer(){
    return(
        <div className="main-footer">
            <div className="footer text-white my-3">
                <img className="my-4" src={img5} />
                <div className="d-flex"><p><a class="footer-link" href="https://www.instagram.com/wellnessu2023?igsh=MzRlODBiNWFlZA==" target="_blank">Instagram</a></p>
                <div className="vline"></div>
                    <p><a class="footer-link" href="" target="_blank">Facebook</a></p>
                <div className="vline"></div>
                    <p><a class="footer-link" href="" target="_blank">Linkedin</a></p>
                <div className="vline"></div>
                    <p> <a class="footer-link" href="https://x.com/wellnessu2023?s=11&t=SWJ0l-89s-2lO5gpuAitQw" target="_blank" >Twitter</a></p>
                </div>
                <div className="d-flex">
                    <p>WellnessU</p>
                    <div className="vline"></div>
                    <p>2024</p><span><i class='bx bx-copyright'></i></span><p>All Rights Are Reserved</p>
                </div>
                
            </div>
        </div>
    )
}