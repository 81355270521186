import React from 'react';
import "./Financial.css";
import StudentrefNavbar from "../../StudentrefNavbar/StudentrefNavbar";
import Footer from '../../Footer/Footer';
import { Link } from 'react-router-dom';
import img from "../../images/support group assets/emotional_1.png";
import img1 from "../../images/support group assets/emotional_2.png"

export default function Emotinal() {
    return (
        <div>
            <StudentrefNavbar />
            <div className="support-group-main">
                <div className="support-group-sidebar">
                    <Link to="/supportgroups/spiritual" className="group-item"><div >Spiritual</div></Link>
                    <Link to="/supportgroups/social" className="group-item"><div >Social</div></Link>
                    <Link to="/supportgroups/career" className="group-item"><div >Career</div></Link>
                    <Link to="/supportgroups/creative" className="group-item"><div >Creative</div></Link>
                    <Link to="/supportgroups/emotional" className="group-item group-active"><div >Emotional</div></Link>
                    <Link to="/supportgroups/environmental" className="group-item"><div >Environmental</div></Link>
                    <Link to="/supportgroups/intellectual" className="group-item"><div >Intellectual</div></Link>
                    <Link to="/supportgroups/financial" className="group-item"><div >Financial</div></Link>
                    <Link to="/supportgroups/physical" className="group-item"><div >Physical</div></Link>
                </div>
                <div className="support-group-body">
                    <div className="group-body-head ">Emotional</div>
                    <div className="group-content">
                        <div className="group-content-para grid-col-span-2">
Emotional well-being is a cornerstone of a fulfilling life, influencing our relationships, work performance, and overall happiness. Developing emotional intelligence starts with recognizing and accepting our emotions, understanding their triggers, and learning healthy ways to manage them. Practices like mindfulness, meditation, and journaling can deepen our emotional awareness and help us regulate our responses to challenging situations. Building strong social connections and seeking professional support when needed are also crucial for maintaining emotional balance. By prioritizing our emotional health and practicing self-care, we can navigate life's ups and downs with greater resilience and compassion for ourselves and others.
</div>
                        <div className="group-content-para">
                        Cultivating emotional well-being is an ongoing journey that requires patience, self-reflection, and a willingness to grow. It involves embracing our vulnerabilities, learning from our experiences, and finding healthy ways to express our emotions. By building a toolkit of strategies for emotional regulation and seeking support from loved ones or mental health professionals, we can enhance our emotional resilience and lead more meaningful lives. Embracing our emotions as a natural part of being human allows us to live authentically and connect more deeply with ourselves and others, leading to a greater sense of fulfillment and well-being.
                        </div>
                        <div className='group-content-boxs'>
                            <div className='group-content-box'><img src={img} alt=""/></div>
                            <div className='group-content-box'><img src={img1} alt=""/></div>
                        </div>
                    </div>
                    <div className="group-body-head ">Community Platform</div>
                    <div className="community-platform">
                        <div className="community-comments">
                            <div className="community-comment-box">
                                <div className="comment-head">
                                    <div className="comment-name " >Jane</div>
                                    <div className="comment-time-head" >
                                        <div className="comment-time" >
                                            3 hours ago
                                        </div>
                                        <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                    </div>
                                </div>
                                <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morbi leo urna molestie at elementum eu. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non.</div>
                                <div className="comment-foot">
                                    <div>2 Comments</div>
                                    <div>Reply</div>
                                </div>
                            </div>
                            <div className="community-reply-box">
                                <div className="community-comment-box">
                                    <div className="comment-head">
                                        <div className="comment-name " >Jhon</div>
                                        <div className="comment-time-head" >
                                            <div className="comment-time" >
                                                1 hour ago
                                            </div>
                                            <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                        </div>
                                    </div>
                                    <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</div>
                                </div>
                                <div className="community-comment-box">
                                    <div className="comment-head">
                                        <div className="comment-name " >Sam</div>
                                        <div className="comment-time-head" >
                                            <div className="comment-time" >
                                                2 hour ago
                                            </div>
                                            <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                        </div>
                                    </div>
                                    <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</div>
                                </div>
                            </div>
                            <div className="community-comment-box">
                                <div className="comment-head">
                                    <div className="comment-name " >Ben</div>
                                    <div className="comment-time-head" >
                                        <div className="comment-time" >
                                            3 hours ago
                                        </div>
                                        <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                    </div>
                                </div>
                                <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morbi leo urna molestie at elementum eu. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non.</div>
                                <div className="comment-foot">
                                    <div>5 Comments</div>
                                    <div>Reply</div>
                                </div>
                            </div>

                        </div>
                        <div className="community-chat-input">
                            <div className="input-box">
                                <input type="text" placeholder="Start a post..."></input>
                                <button><i class='bx bx-up-arrow-alt'></i></button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <Footer/>
        </div >
    )
}