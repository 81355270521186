import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import img6 from "../images/homebg.jpg";
import Footer from "../Footer/Footer";
import  {Link, useNavigate } from "react-router-dom";
import axios from "axios"
import validator from 'validator';
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import "../Login/Login.css";

export default function Studentlogin(){
    const[email,setEmail]=useState(null);
    const[password,setPassword]=useState(null);
    const[repassword,setRepassword]=useState(null);
    const[loginErr,setLoginErr] = useState(false);
    const [signupErr,setSignupErr] = useState(false);
    const[useremail,setUseremail]=useState(null);
    const[userpassword,setUserpassword]=useState(null);
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const navigate=useNavigate()
    const sumbitHandler=async(e)=>{
        e.preventDefault()
        e.stopPropagation()
        if(password===repassword){
            setSignupErr(false);
            
        
        if(!password){
            toast.error("Error Notification !", {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }

        if(!password.match(passwordRegex)){
            setSignupErr(true)
            toast.error("Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one digit")
            return;
        }
        try{
            const config = {
                headers: {
                    "Content-type": "application/json",
                }, 
            };
            const response=await axios.post("https://backend.thewellnessu.com/api/user/register",{email,password},config)
            localStorage.setItem("userInfo", JSON.stringify(response));
            navigate("/studentverify", {state:email})
         }catch(error){
            console.log(error,"Error occured")
            toast.error('Something went wrong!, please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    else{
        setSignupErr(true);
        toast.error("Error Notification !", {
            position: toast.POSITION.TOP_CENTER,
          });
    }

    }
    const loginHandler=async(e)=>{
        e.preventDefault()
        e.stopPropagation()
        
        if(useremail && userpassword ){
            try {
                const config = {
                    headers: {
                        "Content-type": "application/json",
                    },
                };
                const response = await axios.post("https://backend.thewellnessu.com/api/user/login", { useremail, userpassword }, config)
                
                localStorage.setItem("userInfo", JSON.stringify(response));
                console.log(response);
                console.log(response.data.refered);
                if(response.data.refered===true){
                    
                    localStorage.setItem("is_refered", "true")
                    navigate("/studentref", { state: { data: useremail } })
                }
                else {
                    localStorage.setItem("is_refered", "false")
                    
                    navigate("/independent", { state: { data: useremail }  })
                }
                localStorage.setItem("wellnessuser", useremail)
                
            } catch (error) {
                console.log(error, "Error occured")
                setLoginErr(true)
            } 
        }else{
            console.log("Error occured")
            toast.error("Error Notification !", {
                position: toast.POSITION.TOP_CENTER,
            });
            setLoginErr(true)
        }
       

    }
    return(
        <div>
            <div><Navbar/></div>
            <div className="login">
            <img src={img6}/>
            <div className="logindiv1 d-flex ">
            
            <div className="m-4 d-flex flex-column">
                        <h4 className="text-start">Already a member?</h4>
                        <p className="text-start fw-medium">Login here</p>
                        <form >
                            <div className="d-flex flex-column"><label className="text-start my-2 fw-medium">Email ID</label>
                            <input type="email" value={useremail} onChange={(e)=>setUseremail(e.target.value)}/>
                            </div>
                            <div className="d-flex flex-column">
                            <label className="text-start my-2 fw-medium">Password</label>
                            <input type="password" value={userpassword} onChange={(e)=>setUserpassword(e.target.value)}/>
                            </div>
                        </form>
                        <Link className="text-start my-1" to="/forgetpassword">Forget Password/ID</Link>
                        {loginErr && <div className="text-danger">*Email or password is incorrect</div>}
                        <button className="btn my-2 login-button" onClick={(e)=>loginHandler(e)}>Submit</button>
            </div>
            
            <div className="dottedline"></div>
            <div className="m-4 d-flex flex-column">
                        <h4 className="text-start">Not a member yet?</h4>
                        <p className="text-start fw-medium">Register here</p>
                        <form >
                            <div className="d-flex flex-column"><label className="text-start my-2 fw-medium">Email ID</label>
                            <input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                            </div>
                            <div className="d-flex flex-column">
                            <label className="text-start my-2 fw-medium">Password</label>
                                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className="d-flex flex-column">
                            <label className="text-start my-2 fw-medium">Re-enter Password</label>
                            <input type="password" value={repassword} onChange={(e)=>setRepassword(e.target.value)}/>
                            </div>
                        </form>
                        <div className="d-flex  gap-3 my-3" >
                        <span><input type="checkbox" className="input-check" required/></span>
                        <p className="fw-medium">I’ve been referred by a Campus Resource Center</p>
                        </div>
                        
                        {signupErr && <div className="text-danger">*Please fill all fields correctly</div>}
                        
                        <Link to="/studentverify"><button className="btn my-2 login-button" onClick={(e)=>sumbitHandler(e)}>Register</button></Link>
            </div>
            </div>
            

            </div>
            <div className="login-foot" style={{ width:"100%"}}><Footer/></div>
            <ToastContainer />
        </div>
    )
}