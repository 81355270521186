import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import About from './components/About/About';
import Reachout from './components/Reachout/Reachout';
import Action from './components/Action/Action';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './components/Login/Login';
import Studentlogin from './components/Login/Studentlogin';
import CrcDashboard from './components/Crc/CrcDashboard';
import Crcform from './components/Crc/Crcform';
import Crcsumit from "./components/Crc/Crcsumit"
import Studentverify from './components/Studentverify/Studentverify';
import Wellnessexpert from './components/StudentrefPages/Wellnesexperts/Wellnessexpert';
import Financial from './components/StudentrefPages/Supportgroups/Financial';
import Verifysuccessful from './components/Studentverify/Verifysuccessful';
import VerifyUnsuccessful from './components/Studentverify/VerifyUnsuccessful';
import Onboarding from './components/Onboarding/Onboarding';
import Footer from './components/Footer/Footer';
import Independentpage from './components/StudentIndpages/Independentpage/Independentpage';
import Lackingwellness from './components/StudentIndpages/Lackingwellness/Lackingwellness';
import StudentProfile from './components/StudentProfile/StudentProfile';
import Studentedit from './components/StudentProfile/Studentedit';
import Sidepanel from './components/StudentrefPages/Sidepanel/Sidepanel';
import DimensionExpert from './components/StudentrefPages/Wellnesspages/DimensionExpert';
import IndSpiritual from './components/StudentIndpages/Wellnesspages/IndSpiritual';
import Crcpredictive from './components/Crc/Crcpredictive';
import Q1 from './components/Crc/Q1';
import ForgetPassword from './components/ForgetPassword/ForgetPassword';
import ResetPassword from './components/ForgetPassword/ResetPassword';
import Carrer from './components/StudentrefPages/Supportgroups/Carrer';
import Creative from './components/StudentrefPages/Supportgroups/Creative';
import Emotional from './components/StudentrefPages/Supportgroups/Emotinal';
import Envirnomental from './components/StudentrefPages/Supportgroups/Envirnomental';
import Intellectual from './components/StudentrefPages/Supportgroups/Intellectual';
import Physical from './components/StudentrefPages/Supportgroups/Phyiscal';
import Social from './components/StudentrefPages/Supportgroups/Social';
import Spiritual from './components/StudentrefPages/Supportgroups/Spiritual';

function App() {
  return (
    <BrowserRouter>
    <Routes>
     
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/reachout' element={<Reachout/>}/>
      <Route path='/action' element={<Action />}/>
      <Route path='/CRClogin' element={<Login />}/>
      <Route path='/Studentlogin' element={<Studentlogin />}/>
      <Route path='/crcdashboard' element={<CrcDashboard />}/>
      <Route path='/crcform' element={<Crcform/>}></Route>
      <Route path='/crcsumit' element={<Crcsumit/>}></Route>
      <Route path='/studentref' element={<Wellnessexpert/>}></Route>
      <Route path='/supportgroups/financial' element={<Financial/>}></Route>
      <Route path='/lackingwellness' element={<Lackingwellness/>}></Route>
      <Route path='/studentverify' element={<Studentverify />}></Route>
      <Route path='/resetpassword' element={<ResetPassword />}></Route>  
      <Route path='/success' element={<Verifysuccessful/>}></Route>
      <Route path='/unsuccess' element={<VerifyUnsuccessful/>}></Route>
      <Route path='/onboarding' element={<Onboarding/>}></Route>
      <Route path='/independent' element={<Independentpage/>}></Route>
      <Route path='/profile' element={<StudentProfile/>}></Route>
      <Route path='/profile/edit' element={<Studentedit/>}></Route>
      <Route path='/studentref/:dimension' element={<DimensionExpert/>}></Route>
      <Route path='/independent/spiritual' element={<IndSpiritual/>}></Route>
      <Route path='/crcpredictive' element={<Crcpredictive/>}></Route>
      <Route path='/q1' element={<Q1/>}></Route>
      <Route path='/forgetpassword' element={<ForgetPassword/>}></Route>
      <Route path='/supportgroups/career' element={<Carrer/>}></Route>
      <Route path='/supportgroups/creative' element={<Creative/>}></Route>
      <Route path='/supportgroups/emotional' element={<Emotional/>}></Route>
      <Route path='/supportgroups/environmental' element={<Envirnomental/>}></Route>
      <Route path='/supportgroups/intellectual' element={<Intellectual/>}></Route>
      <Route path='/supportgroups/physical' element={<Physical/>}></Route>
      <Route path='/supportgroups/social' element={<Social/>}></Route>
      <Route path='/supportgroups/spiritual' element={<Spiritual/>}></Route>
      

    </Routes>
     
    </BrowserRouter>
    
  );
}

export default App;
