import React from 'react';
import "./Financial.css";
import StudentrefNavbar from "../../StudentrefNavbar/StudentrefNavbar";
import Footer from '../../Footer/Footer';
import { Link } from 'react-router-dom';
import img from "../../images/support group assets/social_1.png";
import img1 from "../../images/support group assets/social_2.png"

export default function Social() {
    return (
        <div>
            <StudentrefNavbar />
            <div className="support-group-main">
                <div className="support-group-sidebar">
                    <Link to="/supportgroups/spiritual" className="group-item"><div >Spiritual</div></Link>
                    <Link to="/supportgroups/social" className="group-item group-active"><div >Social</div></Link>
                    <Link to="/supportgroups/career" className="group-item"><div >Career</div></Link>
                    <Link to="/supportgroups/creative" className="group-item"><div >Creative</div></Link>
                    <Link to="/supportgroups/emotional" className="group-item"><div >Emotional</div></Link>
                    <Link to="/supportgroups/environmental" className="group-item"><div >Environmental</div></Link>
                    <Link to="/supportgroups/intellectual" className="group-item"><div >Intellectual</div></Link>
                    <Link to="/supportgroups/financial" className="group-item"><div >Financial</div></Link>
                    <Link to="/supportgroups/physical" className="group-item"><div >Physical</div></Link>
                </div>
                <div className="support-group-body">
                    <div className="group-body-head ">Social</div>
                    <div className="group-content">
                        <div className="group-content-para grid-col-span-2">Navigating the social domain is essential for fostering meaningful connections and building a supportive community. Social skills, including effective communication, empathy, and understanding social cues, play a crucial role in this process. To enhance your social well-being, focus on improving your communication skills through active listening, asking open-ended questions, and being mindful of your body language. Building relationships requires empathy, consistency, and shared activities, which can strengthen bonds with others and create a sense of belonging.
</div>
                        <div className="group-content-para">
                        Engaging in community activities, such as volunteering, networking, and building support systems, can further enrich your social life. Developing social awareness involves understanding cultural sensitivity, social norms, and seeking feedback to improve your interactions. Overcoming social challenges may require stepping out of your comfort zone, seeking professional help if needed, and practicing social skills regularly. By embracing these principles, you can enhance your social skills and navigate social situations with confidence and ease.
                        </div>
                        <div className='group-content-boxs'>
                            <div className='group-content-box'><img src={img} alt=""/></div>
                            <div className='group-content-box'><img src={img1} alt=""/></div>
                        </div>
                    </div>
                    <div className="group-body-head ">Community Platform</div>
                    <div className="community-platform">
                        <div className="community-comments">
                            <div className="community-comment-box">
                                <div className="comment-head">
                                    <div className="comment-name " >Jane</div>
                                    <div className="comment-time-head" >
                                        <div className="comment-time" >
                                            3 hours ago
                                        </div>
                                        <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                    </div>
                                </div>
                                <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morbi leo urna molestie at elementum eu. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non.</div>
                                <div className="comment-foot">
                                    <div>2 Comments</div>
                                    <div>Reply</div>
                                </div>
                            </div>
                            <div className="community-reply-box">
                                <div className="community-comment-box">
                                    <div className="comment-head">
                                        <div className="comment-name " >Jhon</div>
                                        <div className="comment-time-head" >
                                            <div className="comment-time" >
                                                1 hour ago
                                            </div>
                                            <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                        </div>
                                    </div>
                                    <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</div>
                                </div>
                                <div className="community-comment-box">
                                    <div className="comment-head">
                                        <div className="comment-name " >Sam</div>
                                        <div className="comment-time-head" >
                                            <div className="comment-time" >
                                                2 hour ago
                                            </div>
                                            <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                        </div>
                                    </div>
                                    <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</div>
                                </div>
                            </div>
                            <div className="community-comment-box">
                                <div className="comment-head">
                                    <div className="comment-name " >Ben</div>
                                    <div className="comment-time-head" >
                                        <div className="comment-time" >
                                            3 hours ago
                                        </div>
                                        <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                    </div>
                                </div>
                                <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morbi leo urna molestie at elementum eu. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non.</div>
                                <div className="comment-foot">
                                    <div>5 Comments</div>
                                    <div>Reply</div>
                                </div>
                            </div>

                        </div>
                        <div className="community-chat-input">
                            <div className="input-box">
                                <input type="text" placeholder="Start a post..."></input>
                                <button><i class='bx bx-up-arrow-alt'></i></button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <Footer/>
        </div >
    )
}