import React from "react";
import "../Action/Action.css"
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import vid1 from "../Action/assets/student.mp4";
import vid2 from "../Action/assets/crc.mp4";

export default function Action(){

    return(
        <div>
            <Navbar/>
        <div className="container seeaction" id="action">
            <div className="action">
            <div>
                        <p className="">
                            <b>Student's Journey</b> : The students have to first register with the wellnessu platform, where they'll have option to choose if they are refered by a campus resource center. If they are refered by the campus resource center then they'll chat with 9 dimension experts or else central AI agent will determine the two lacking dimensions. The students will be suggested support groups based on their lacking dimensions.
                            <br />
                            <br />
                            <b>Resource Center's Journey</b> : The user has to register if they are not already assoiciated with the Campus resource center, by entering the details about wellness dimension address, student outreach, previous experience etc. while registering. The Campus resource center user will be able to view CRC dashboard, where the student's dimension analytics is displayed. 
                 </p>
            </div>
            <div className="d-flex sub-action">
                        <div className="rectangle one"><video controls width="100%">
                            <source src={vid1} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video></div>
                        <div className="rectangle two"><video controls width="100%">
                            <source src={vid2} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video></div>
            </div>
            </div>
            </div>  
            <div className="action-footer" style={{width:"100%"}}><Footer/></div>
        </div>
    )
}