import React from 'react';
import "./Financial.css";
import StudentrefNavbar from "../../StudentrefNavbar/StudentrefNavbar";
import Footer from '../../Footer/Footer';
import { Link } from 'react-router-dom';
import img from "../../images/support group assets/creativity_1.png";
import img1 from "../../images/support group assets/creativity_2.png"

export default function Creative() {
    return (
        <div>
            <StudentrefNavbar />
            <div className="support-group-main">
                <div className="support-group-sidebar">
                    <Link to="/supportgroups/spiritual" className="group-item"><div >Spiritual</div></Link>
                    <Link to="/supportgroups/social" className="group-item"><div >Social</div></Link>
                    <Link to="/supportgroups/career" className="group-item"><div >Career</div></Link>
                    <Link to="/supportgroups/creative" className="group-item group-active"><div >Creative</div></Link>
                    <Link to="/supportgroups/emotional" className="group-item"><div >Emotional</div></Link>
                    <Link to="/supportgroups/environmental" className="group-item"><div >Environmental</div></Link>
                    <Link to="/supportgroups/intellectual" className="group-item"><div >Intellectual</div></Link>
                    <Link to="/supportgroups/financial" className="group-item"><div >Financial</div></Link>
                    <Link to="/supportgroups/physical" className="group-item"><div >Physical</div></Link>
                </div>
                <div className="support-group-body">
                    <div className="group-body-head ">Creative</div>
                    <div className="group-content">
                        <div className="group-content-para grid-col-span-2">
Creativity is a vital aspect of personal growth and innovation, regardless of your profession or interests. To foster creativity, commit to making it a priority in your life by setting aside dedicated time for creative activities. Expand your knowledge by immersing yourself in areas that interest you, such as reading, attending workshops, and exploring the works of others. Cultivating curiosity and embracing risk-taking are also key components of creativity, as they allow you to explore new ideas and approaches.
</div>
                        <div className="group-content-para">
                        Investing in your future is another important aspect of personal finance. Consider creating a diverse portfolio that aligns with your financial goals and risk tolerance. Managing debt wisely is crucial to avoid financial burden. Prioritize paying off high-interest debt and consider consolidating debts for better terms. Improving your credit score by paying bills on time and keeping credit card balances low is key. Saving for retirement should start early to benefit from compound interest. Insurance is important for protecting your assets from unexpected events. Continuously educate yourself about personal finance to make smart financial decisions, and seek professional advice if needed.
                        </div>
                        <div className='group-content-boxs'>
                            <div className='group-content-box'><img src={img} alt=""/></div>
                            <div className='group-content-box'><img src={img1} alt=""/></div>
                        </div>
                    </div>
                    <div className="group-body-head ">Community Platform</div>
                    <div className="community-platform">
                        <div className="community-comments">
                            <div className="community-comment-box">
                                <div className="comment-head">
                                    <div className="comment-name " >Jane</div>
                                    <div className="comment-time-head" >
                                        <div className="comment-time" >
                                            3 hours ago
                                        </div>
                                        <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                    </div>
                                </div>
                                <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morbi leo urna molestie at elementum eu. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non.</div>
                                <div className="comment-foot">
                                    <div>2 Comments</div>
                                    <div>Reply</div>
                                </div>
                            </div>
                            <div className="community-reply-box">
                                <div className="community-comment-box">
                                    <div className="comment-head">
                                        <div className="comment-name " >Jhon</div>
                                        <div className="comment-time-head" >
                                            <div className="comment-time" >
                                                1 hour ago
                                            </div>
                                            <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                        </div>
                                    </div>
                                    <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</div>
                                </div>
                                <div className="community-comment-box">
                                    <div className="comment-head">
                                        <div className="comment-name " >Sam</div>
                                        <div className="comment-time-head" >
                                            <div className="comment-time" >
                                                2 hour ago
                                            </div>
                                            <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                        </div>
                                    </div>
                                    <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</div>
                                </div>
                            </div>
                            <div className="community-comment-box">
                                <div className="comment-head">
                                    <div className="comment-name " >Ben</div>
                                    <div className="comment-time-head" >
                                        <div className="comment-time" >
                                            3 hours ago
                                        </div>
                                        <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                    </div>
                                </div>
                                <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morbi leo urna molestie at elementum eu. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non.</div>
                                <div className="comment-foot">
                                    <div>5 Comments</div>
                                    <div>Reply</div>
                                </div>
                            </div>

                        </div>
                        <div className="community-chat-input">
                            <div className="input-box">
                                <input type="text" placeholder="Start a post..."></input>
                                <button><i class='bx bx-up-arrow-alt'></i></button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <Footer/>
        </div >
    )
}