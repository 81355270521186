import React from "react";
import "../Reachout/Reachout.css"
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

export default function Reachout(){
    return(
        <div>
            <Navbar/>
        <div className="container " id="reachoutsee">
            
        <div className="reachout d-flex ">
            <div className="reach-container ">
                <h3 className="text-start my-5">We would love to connect with you!</h3>
                        <p className="text-start my-2">
                     If you are a Resource Center and would like to parterner with us or if you are a student with some queries, please fill this form to the right.    
                </p>
                <p className="text-start my-4">Email: wellnessU@gmail.com</p>
                <div className="social text-start my-2">
                <a href="https://www.instagram.com/wellnessu2023?igsh=MzRlODBiNWFlZA==" target="_blank"><i class='bx bxl-instagram '></i></a>
                <a><i class='bx bxl-linkedin-square mx-4' ></i></a>
                <a><i class='bx bxl-facebook-square mx-4' ></i></a>
                <a href="https://x.com/wellnessu2023?s=11&t=SWJ0l-89s-2lO5gpuAitQw" target="_blank"><i class='bx bxl-twitter mx-4' ></i></a>
                </div>
                
            </div>
            <div>
            
                <div className="form-floating">
                <h3>Say hello!</h3>
                    <form className="d-flex flex-column">
                        <div className="d-flex flex-column my-2">
                        <label for="name" className="text-start lh-lg">Name</label>
                        <input type="text" id="name"></input>
                        </div>
                        <div className="d-flex flex-column my-2">
                        <label for="email" className="text-start">Email ID</label>
                        <input type="email" id="email"></input>
                        </div>
                        <div className="d-flex flex-column my-2">
                        <label for="message" className="text-start">Message</label>
                        <input type="message" id="message"></input>
                        </div>
                       
                        <button type="submit" className="reachout-button">Submit</button>
                       
                    </form>
                    
                </div>
            </div>
        </div>
        </div>
        <div className="reach-footer" style={{width:"100%", marginTop:"40%"}}><Footer/></div>
       
        </div>
    )
}