import React from 'react';
import "./Financial.css";
import StudentrefNavbar from "../../StudentrefNavbar/StudentrefNavbar";
import Footer from '../../Footer/Footer';
import { Link } from 'react-router-dom';
import img from "../../images/support group assets/Intellectual_1.png";
import img1 from "../../images/support group assets/Intellectual_2.png"

export default function Intellectual() {
    return (
        <div>
            <StudentrefNavbar />
            <div className="support-group-main">
                <div className="support-group-sidebar">
                    <Link to="/supportgroups/spiritual" className="group-item"><div >Spiritual</div></Link>
                    <Link to="/supportgroups/social" className="group-item"><div >Social</div></Link>
                    <Link to="/supportgroups/career" className="group-item"><div >Career</div></Link>
                    <Link to="/supportgroups/creative" className="group-item"><div >Creative</div></Link>
                    <Link to="/supportgroups/emotional" className="group-item"><div >Emotional</div></Link>
                    <Link to="/supportgroups/environmental" className="group-item"><div >Environmental</div></Link>
                    <Link to="/supportgroups/intellectual" className="group-item group-active"><div >Intellectual</div></Link>
                    <Link to="/supportgroups/financial" className="group-item"><div >Financial</div></Link>
                    <Link to="/supportgroups/physical" className="group-item"><div >Physical</div></Link>
                </div>
                <div className="support-group-body">
                    <div className="group-body-head ">Intellectual</div>
                    <div className="group-content">
                        <div className="group-content-para grid-col-span-2">
                            Enhancing intellectual well-being involves a multifaceted approach to stimulate mental growth and cognitive agility. Cultivating a love for learning through activities like reading, attending lectures, and exploring new hobbies is key to keeping the mind sharp and engaged. Embracing curiosity fuels intellectual growth by encouraging the asking of questions and the pursuit of answers, leading to new insights and understanding. Engaging in critical thinking is essential for evaluating information effectively and forming well-reasoned conclusions. Expanding horizons by exposing oneself to diverse perspectives and disciplines fosters a broader understanding and encourages creative problem-solving. Additionally, practices like reflection, writing, debating, teaching, setting goals, and staying informed contribute to a vibrant intellectual life that benefits both individuals and society as a whole.
</div>
                        <div className="group-content-para">
                            To delve deeper into strategies for intellectual well-being, resources like LifeHack's ways to boost intellectual wellness, Goalcast's definition and examples of intellectual wellness, and Columbia University's tips on improving intellectual well-being offer detailed guidance and insights. These resources provide further avenues for enhancing intellectual practices and nurturing a thriving intellectual life.
                        </div>
                        <div className='group-content-boxs'>
                            <div className='group-content-box'><img src={img} alt=""/></div>
                            <div className='group-content-box'><img src={img1} alt=""/></div>
                        </div>
                    </div>
                    <div className="group-body-head ">Community Platform</div>
                    <div className="community-platform">
                        <div className="community-comments">
                            <div className="community-comment-box">
                                <div className="comment-head">
                                    <div className="comment-name " >Jane</div>
                                    <div className="comment-time-head" >
                                        <div className="comment-time" >
                                            3 hours ago
                                        </div>
                                        <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                    </div>
                                </div>
                                <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morbi leo urna molestie at elementum eu. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non.</div>
                                <div className="comment-foot">
                                    <div>2 Comments</div>
                                    <div>Reply</div>
                                </div>
                            </div>
                            <div className="community-reply-box">
                                <div className="community-comment-box">
                                    <div className="comment-head">
                                        <div className="comment-name " >Jhon</div>
                                        <div className="comment-time-head" >
                                            <div className="comment-time" >
                                                1 hour ago
                                            </div>
                                            <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                        </div>
                                    </div>
                                    <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</div>
                                </div>
                                <div className="community-comment-box">
                                    <div className="comment-head">
                                        <div className="comment-name " >Sam</div>
                                        <div className="comment-time-head" >
                                            <div className="comment-time" >
                                                2 hour ago
                                            </div>
                                            <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                        </div>
                                    </div>
                                    <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</div>
                                </div>
                            </div>
                            <div className="community-comment-box">
                                <div className="comment-head">
                                    <div className="comment-name " >Ben</div>
                                    <div className="comment-time-head" >
                                        <div className="comment-time" >
                                            3 hours ago
                                        </div>
                                        <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                    </div>
                                </div>
                                <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morbi leo urna molestie at elementum eu. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non.</div>
                                <div className="comment-foot">
                                    <div>5 Comments</div>
                                    <div>Reply</div>
                                </div>
                            </div>

                        </div>
                        <div className="community-chat-input">
                            <div className="input-box">
                                <input type="text" placeholder="Start a post..."></input>
                                <button><i class='bx bx-up-arrow-alt'></i></button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <Footer/>
        </div >
    )
}