import React from "react";
import "../Navbar/Navbar.css"
import img3 from "../images/logo.png"
import { Link } from "react-router-dom";

export default function Navbar() {
    return (

        <div className="nav-bar">
            <nav>
                <div className="logo-img"><Link to="/"><img src={img3} /></Link></div>
                <div className="nav-items ">
                    <div><Link to="/" className="nav-item" >Home</Link></div>
                    <div><Link to="/about" className="nav-item" >About</Link></div>
                    <div><Link to="/reachout" className="nav-item" >Reach Out?</Link></div>
                    <div><Link to="/action" className="nav-item">See it in Action</Link></div>
                    <div className="dropdown">
                        <Link className="nav-item">Login</Link>
                        <div className="dropdown-content ">
                            <Link  style ={{"textDecoration":"none"}} to="/Studentlogin"><p>Student</p></Link>
                            <Link style ={{"textDecoration":"none"}}  to="/CRClogin"><p>Resource center</p></Link>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}