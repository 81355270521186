import React from "react";
import "../StudentrefNavbar/StudentrefNavbar.css"
import img3 from "../images/logo.png"
import { Link, useNavigate } from "react-router-dom"; 

export default function StudentrefNavbar(props) {
   
    const navigate=useNavigate();
    const handleLogout=()=>{
        navigate("/Studentlogin")
    }
    const email = localStorage.getItem('email');
    const is_refered = localStorage.getItem("is_refered");
    console.log(email);
    console.log("is refered ", is_refered);
    return(
        <div className="nav-bar">
            <nav>
                <div className="logo-img"><Link to="/"><img src={img3} /></Link></div>
                <div className="nav-items ">
                    {is_refered === 'true' ? (<div><Link to="/studentref" state={{ data: email }} className="nav-item" >Wellness Expert</Link></div>) :
                        (<div><Link to="/independent" state={{ data: email }} className="nav-item" >Central AI Agent</Link></div>)
                    }
                   
                        <div><Link to="/supportgroups/financial" className="nav-item" >Support Groups</Link></div>
                        <div><Link to="/" className="nav-item" >Your Report</Link></div>
                    <div><Link to="/profile" state={{ data: props.email }} className="nav-item" >Profile</Link></div>
                        <div onClick={handleLogout}><Link to="/" className="nav-item">Logout</Link></div>
                        
                </div>
            </nav>
        </div>
    )
}