import React from "react";
import "./Chats.css";
import sos from "../images/sos.png";
export default function User(props) {
    return (
        <div className ="chatsAi">
            <div className="chat-user">
                <div className="chat-msg">
                    <p className="chat-content">{props.text} </p>
                    
                </div>
                <div className="chat-img">
                    <img src={sos} />
                </div>

            </div>
        </div>
        
    )
}