import React from "react"
import Navbar from "../Navbar/Navbar"
import img6 from "../images/homebg.jpg";
import img7 from "../images/unsuccess.png"
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";

export default function VerifyUnsuccessful(){
    return(
        <div>
            <div><Navbar/></div>
            <div>
            <img className="sumit-img" src={img6}/>
            <div className="logindiv">
               <img src={img7} className="my-2 illustrator"/>
                <p className="crc-p m-2">Unable to verify your email.</p>
                <Link className="btn login-button">Resend OTP</Link>
            </div>
            </div>
           <div className="crc-sumit"><Footer/></div>
        </div>
    )
}