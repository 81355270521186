import React from "react";
import "./Chats.css";
import group49 from "../images/Group 49.png";
export default function Chatai( props) {
    return (
        <div className ="chatsAI">
            <div className="chat-ai">
                <div className="chat-img">
                    <img src={group49} />
                </div>
                <div className="chat-msg">
                    {
                        props.processing ? (<p><div class="blinking-dot"></div></p>)
                            : (<p className = "chat-content">{props.text}</p>)}
                    
                    
                </div>
            </div>
        </div>
        
    )
}