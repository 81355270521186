import React from 'react';
import "../Team/Team.css";
import ceo  from '../images/ceo.jpg';
import cto  from '../images/cto.jpg';
import coo from '../images/coo.jpg';
import cfo from '../images/cfo.jpg';
import cffo from '../images/cffo.jpg';
import ux from '../images/ux.jpg';
import dev from '../images/dev.jpg';
export default function Team(){
    return(
        <div className='container'>
            <div className='team-container'>
            <p className="team text-start">Meet the Core Team</p>
            <div className='row team-cont'>
            <div className='col-3 team-content'>
                        <div className='square'><img src={ ceo } width="200px" height="200px"/></div>
                <h4>Avantika Singh</h4>
                        <h5>Chief Executive Officer </h5>
                        <h5>AI Chip Design,EECS</h5>
                        <h5>Minors in Entrepreneurship</h5>
            </div>
            <div className='col-3 team-content'>
                        <div className='square'><img src={cto} width="200px" height="200px" /></div>
                <h4>Neil Nagaraj</h4>
                        <h5>Chief Technology Officer </h5>
                        <h5>AI, Computer Science</h5>
            </div>
            <div className='col-3 team-content'>
                        <div className='square'><img src={coo} width="200px" height="200px" /></div>
                <h4>Jaideep Rao Alladi</h4>
                        <h5>Chief Operating Officer </h5>
                        <h5>Mechanical & Aerospace Engineering</h5>
                        <h5>Minors in Product Management</h5>
            </div>
            <div className='col-3 team-content'>
                        <div className='square'><img src={cfo} width="200px" height="200px" /></div>
                <h4>Himanshu Singh</h4>
                        <h5>Chief Financial Officer</h5>
                        <h5>Due Diligence Lead,</h5>
                        <h5>Cerracap Ventures</h5>
                    </div>
        
            <div className='col-3 team-content'>
                        <div className='square'><img src={ux} width="200px" height="200px" /></div>
                        <h4>Avani Rao Alladi</h4>
                        <h5>Chief UX Designer</h5>
                        <h5>Human Computer Interaction</h5>
                    </div>
            <div className='col-3 team-content'>
                <div className='square'><img src={dev} width="200px" height="200px" /></div>
                <h4>Bhargav Kar</h4>
                <h5>Developer</h5>
                
            </div>
            </div>
            </div>
            
        </div>
    )
}