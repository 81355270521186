import React from "react";
import CRCnavbar from "./CRCnavbar";
import "../Crc/CrcDashboard.css"
import Footer from "../Footer/Footer";
import { useState } from "react";

export default function Crcpredictive() {
    return (
        <div>
            <div><CRCnavbar /></div>

            <div className="dashboard-container">
                <div className="rect-container">
                    <iframe className="rect-crc" src="https://app.powerbi.com/view?r=eyJrIjoiYjFlMGVkZDYtNDk5ZC00MzdjLWIwZDQtMzllYzk1MGMzNjgwIiwidCI6IjQ5ZjBiNjU4LWE2NmItNDMyZC05NzEzLTZhZmRhNzNiM2ZlYSIsImMiOjZ9"></iframe>
                </div>
            </div>
            <div className="crc-footer"><Footer /></div>
        </div>
    )
}