import React, { useState } from "react";
import "./Independentpage.css";
import group49 from "../../images/Group 49.png";
import sos from "../../images/sos.png";
import StudentrefNavbar from "../../StudentrefNavbar/StudentrefNavbar";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Chatai from "../../Chatcomponent/Chatai";
import User from "../../Chatcomponent/User"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function Independentpage(){
    const[messages,setMessages]=useState([])
    const[inputText,setInputText]=useState('')
    const [showInput,setShowInput]=useState(true);
    const location = useLocation();
    const data1 = localStorage.getItem("wellnessuser");
    
    const responseData ="!@#$^"
    
    const handleEndpoint=async(e)=>{
        e.preventDefault()
        e.stopPropagation()
        try{

            const config = {
                headers: {
                    "Content-type": "application/json",
                },
            }
            const response=await axios.post(`https://chat.thewellnessu.com/chat?username=${data1}&is_user_initiated=false&dimension=central_ai&chat_mock=flase`,{"text":inputText},config);
            if(response.data.messages[0].includes(responseData)){
                setMessages([...messages, { usertext :inputText, text: "Thank you for Chating with me, you can now return to the home page"}])
                setShowInput(false)
            }else{
                setMessages([...messages, { usertext :inputText, text: response.data.messages[0]}]);
                setShowInput(true)
            }
            
              
            setInputText('')
            console.log(messages)
            
        }catch(error){
            toast.error("Error in fetching item")
            console.log("Error in fetching item")
        }

    }
    return(
        <div className ="independent-main">
            <div><StudentrefNavbar email = {data1}/></div>
            <div className="independentpage">
                <div className="fw-bold">Central AI Agent</div>
                <div className="independent-chat">
                    <div className = "allChats">
                        {messages.map((message, index) => (
                            <div key={index}>
                                <User text={message.usertext} />
                                <Chatai text={message.text} />
                            </div>
                        ))}
                    </div>

                    
                            {showInput && (
                                <div className="chats-input">
                        
                                <form onSubmit={(e) => handleEndpoint(e)}>
                                <input type="text" name='message' value={inputText} onChange={(e) => setInputText(e.target.value)} placeholder="Type a message" autoFocus></input>
                                <button><i class='bx bx-up-arrow-alt'></i></button>
                            </form>
                            
                            </div>
                            )}
                       
                </div>
                
            </div>
            <ToastContainer/>
        </div>
    )
}