import React, { useState, useRef } from "react"
import StudentrefNavbar from "../../StudentrefNavbar/StudentrefNavbar"
import Sidepanel from "../Sidepanel/Sidepanel"
import group49 from "../../images/Group 49.png"
import sos from "../../images/sos.png";
import "../Wellnesspages/wellnesspages.css"
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Chatai from "../../Chatcomponent/Chatai";
import User from "../../Chatcomponent/User"

export default function DimensionExpert(){
    const[messages,setMessages]=useState([])
    const[inputText,setInputText]=useState('')
    const location=useLocation();
    const [showInput, setShowInput] = useState(true);
    const [processingPrompt, setProcessingPrompt] = useState(false);
    const responseData="*$&#";
    const data = location.state?.data;
    const email = localStorage.getItem('wellnessuser');

   
    
    const handleEndpoint=async(e)=>{
        e.preventDefault()
        e.stopPropagation()
       
       
        try{
            setProcessingPrompt(true);
            setMessages([...messages, {usertext: inputText}])
            setInputText('')
            const config = {
                headers: {
                    "Content-type": "application/json",
                },
            }
            
            const response=await axios.post(`https://chat.thewellnessu.com/chat?username=${email}&dimension=${data}&chat_mock=false`,{"text":inputText},config);
            if(response.data.messages[0].includes(responseData)){
                setMessages([...messages, { usertext: inputText, text: "Thank you for Chating with me, you can now return to the home page"}]);
                
                setShowInput(false)
                setProcessingPrompt(false);
            }else{
                setMessages([...messages, { usertext: inputText, text: response.data.messages[0]}]);
                setShowInput(true)
                setProcessingPrompt(false);
            }
            
            
            
            console.log(messages)
            
        }catch(error){
            toast.error("Error in fetching item")
            console.log("Error in fetching item")
        }

    }
    return(
        <div>
            <div><StudentrefNavbar/></div>
            <div className="row main-wellness">
                <div className="col-3"><Sidepanel/></div>
                <div className=" col-8">
                <div className="fw-bold my-2">{data}</div>
                    <div className="independent-chat">
                        <div className="allChats">
                            {messages.map((message, index) => (
                                <div key={index}>
                                    <User text={message.usertext} />
                                    {
                                        index === (messages.length - 1) ?
                                            (<Chatai text={message.text} processing={processingPrompt} />)
                                            : (<Chatai text={message.text} />)
                                    }
                                  
                                </div>
                            ))}
                        </div>
                        
                            {showInput && (
                                <div className="chats-input">
                        
                                <form onSubmit={(e) => handleEndpoint(e)}>
                                <input type="text" name='message' value={inputText} onChange={(e) => setInputText(e.target.value)} placeholder="Type a message" autoFocus></input>
                                    {
                                        processingPrompt ? (<button type="button" class="rounded-full border-2 border-gray-950 p-1 dark:border-gray-200" aria-label="Stop generating"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="h-2 w-2 text-token-text-primary" height="16" width="16"><path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z" stroke-width="0"></path></svg></button>):
                                            (<button><i class='bx bx-up-arrow-alt'></i></button>)
                               }
                            </form>
                            
                            </div>
                            )}
                        
                        
                    </div>
            </div>
            </div>
            <div>
                <div className="floating-support">
                    <h5 className="fw-bolder">Here are the support resources and groups to help you navigate your challenges</h5>
                    <div>
                    <button className="btn login-button ">Financial</button>
                    <button className="btn login-button ">Physical</button>
                    </div>
                    <button className="x-mark cross"><i class='bx bxs-x-circle'></i></button>
                </div>
            </div>
        </div>
    )
}